export const links = [
  //{url: '/', text: 'Collector'},
  {url: '/nouvelles/', text: 'Nouvelles'},
  {url: '/boutique/', text: 'Boutique'},
  {url: '/agenda/', text: 'Agenda'},
  {url: '/spectacles/', text: 'Spectacles'},
  {url: '/contact/', text: 'Contact'},
]

export {Desktop} from './Desktop'
export {Mobile} from './Mobile'
export {MobileMenuButton} from './MobileMenuButton'

import {format, parseISO, isFuture} from 'date-fns'

export function cn(...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs({slug}) {
  return (slug || {}).current
}

export function filterOutDocsPublishedInTheFuture({publishedAt}) {
  return !isFuture(parseISO(publishedAt))
}

export function getBlogPath(publishedAt, slug) {
  return `/blog/${format(parseISO(publishedAt), 'yyyy/MM')}/${
    slug.current || slug
  }/`
}

export function buildImageObj(source) {
  const imageObj = {
    asset: {_ref: source.asset._ref || source.asset._id},
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function toPlainText(blocks) {
  if (!blocks) {
    return ''
  }
  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      return block.children.map(child => child.text).join('')
    })
    .join('\n\n')
}

export function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}
